Livewire.on('toastr::success', function(message) {
    toastr.success(message.text, 'Sucesso', {
        progressBar: true,
        timeOut: 10000,
        showMethod: "slideDown",
        hideMethod: "fadeOut",
        positionClass: "toast-bottom-right",
    });
})

Livewire.on('toastr::info', function(message) {
    toastr.info(message.text, 'Info', {
        progressBar: true,
        timeOut: 10000,
        showMethod: "slideDown",
        hideMethod: "fadeOut",
        positionClass: "toast-bottom-right",
    });
})

Livewire.on('toastr::warning', function(message) {
    toastr.warning(message.text, 'Oops!', {
        progressBar: true,
        timeOut: 10000,
        showMethod: "slideDown",
        hideMethod: "fadeOut",
        positionClass: "toast-bottom-right",
    });
})

Livewire.on('toastr::error', function(message) {
    toastr.error(message.text, 'Erro', {
        progressBar: true,
        timeOut: 10000,
        showMethod: "slideDown",
        hideMethod: "fadeOut",
        positionClass: "toast-bottom-right",
    });
})